import styles from './how-it-works.module.scss'

const HowDoesItWork = ({ isVans }) => {
  return (
    <section className={styles.container}>
      <div className={styles.grid}>
        <div className={styles.column}>
          <div className={styles.heading}>
            <h2>What is {isVans ? 'van' : 'car'} leasing and how does it work?</h2>
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.text}>
            <p>
              <span>{isVans ? 'Van' : 'Car'} leasing is a rental agreement</span> which allows you
              to have a vehicle for an agreed period of time, typically two to four years, in
              exchange for fixed monthly payments. Leasing a {isVans ? 'van' : 'car'} for a longer
              period of time may reduce this fixed monthly cost, but that will depend on the type of
              vehicle you choose as well as the terms of your leasing contract. You will also need
              to cover an initial rental payment upfront, which may be the equivalent of one to
              twelve monthly payments.
            </p>
            <p>
              At the end of your {isVans ? 'van' : 'car'} leasing term, you won&apos;t own the
              vehicle and will be required to return it. You may be able to extend your{' '}
              {isVans ? 'van' : 'car'} lease or lease a brand new {isVans ? 'van' : 'car'}, but you
              will not have the option to buy the vehicle you have been leasing.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HowDoesItWork
